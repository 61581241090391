module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "VisualEyes", // Navigation and Site Title
  //VisualEyes | Your AI design assistant
  titleAlt: "VisualEyes", // Title for JSONLD
  description:
    "Analyze user behavior, enhance UX, and optimize designs by utilizing AI generated Attention Heatmaps and Clarity score.",
  // "VisualEyes is an AI alternative tool for eye-tracking studies and preference tests providing Attention Heatmaps and Clarity score.",
  // "Design beautiful, accessible and efficient user interfaces through continuous feedback. VisualEyes AI-powered assistant is available on Adobe XD, Figma, Sketch.",
  // headline: "Optimize your UX via instant Attention Heatmaps and Clarity score", // Headline for schema.org JSONLD
  headline: "Optimize your UX via Attention Heatmaps and Clarity", // Headline for schema.org JSONLD
  url: "https://www.visualeyes.design", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  ogLanguage: "en_US", // Facebook Language
  ogImage:
    "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website-og-image.jpg",
  twitterImage:
    "https://d34cuim5v38p5e.cloudfront.net/visualeyes/twitter-og-image.jpg",
  keywords: [
    "heatmap",
    "attention",
    "eye-tracking",
    "eyetracking",
    "design",
    "plugin",
    "predictive",
    "heat",
    "map",
    "aesthetics",
  ],

  // JSONLD / Manifest
  favicon: `assets/favicon.ico`,
  shortName: "VisualEyes",
  author: "@dmraptis",
  organisation: "Loceye",
  themeColor: "#3E20DD",
  backgroundColor: "#3E20DD",

  twitter: "@VisualEyesHQ",
  //   facebook: "loceye",
  googleAnalyticsID: "UA-99671636-3",
  googleTagManagerID: "GTM-PB5K3NM",
  googleOptimizeID: "GTM-NZ6J49M",
  hotjarId: "1585995",
  hotjarSv: "6",
  pixelId: "174933950276277",

  fontFamily: "Yantramanav",
  fontVariant: [`300`, `400`, `500`, `700`],
};
